import _toConsumableArray from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import { mapState } from "vuex";
import { HotWordApi } from "@/api";
import Mixin from "@/utils/mixin";
import { addWordColumns } from "./config/addColumns";
export default {
  name: "editWord",
  data: function data() {
    return {
      createForm: this.$form.createForm(this, {
        name: "word_home"
      }),
      loading: false,
      dataSource: [],
      columns: addWordColumns,
      previewImage: "",
      previewVisible: false,
      secategoryList: [],
      begintime: "",
      endtime: "",
      type: ""
    };
  },
  mixins: [Mixin],
  computed: _objectSpread(_objectSpread({}, mapState("hotwordaddgoods", ["wordrow"])), {}, {
    editId: function editId() {
      return this.$route.query.id;
    },
    showDisable: function showDisable() {
      return +this.$route.query.key === 1;
    },
    splitImg: function splitImg() {
      return function (record) {
        if (record.imgurls.indexOf(",") !== -1) {
          return record.imgurls.split(",")[0];
        } else if (record.imgurls.indexOf("|") !== -1) {
          return record.imgurls.split("|")[0];
        } else {
          return record.imgurls;
        }
      };
    }
  }),
  mounted: function mounted() {
    this.getDetail();
  },
  methods: {
    addList: function addList() {
      this.$router.push("/hotWord/addlist?type=" + this.type);
    },
    getDetail: function getDetail() {
      var _this = this;
      HotWordApi.hotworddetail({
        id: this.editId
      }).then(function (res) {
        console.log(res);
        var _res$hotword = res.hotword,
          name = _res$hotword.name,
          type = _res$hotword.type,
          begintime = _res$hotword.begintime,
          endtime = _res$hotword.endtime;
        _this.dataSource = res.list;
        _this.createForm.setFieldsValue({
          name: name,
          type: type,
          time: [begintime, endtime]
        });
        _this.begintime = begintime;
        _this.endtime = endtime;
        _this.columns = addWordColumns;
        _this.type = type;
        console.log(type);
        if (_this.$store.state.hotwordaddgoods.wordrow && _this.$store.state.hotwordaddgoods.wordrow.length > 0) {
          _this.dataSource = [].concat(_toConsumableArray(_this.dataSource), _toConsumableArray(_this.$store.state.hotwordaddgoods.wordrow));
          var obj = {};
          _this.dataSource = _this.dataSource.reduce(function (cur, next) {
            obj[next.id] ? "" : obj[next.id] = true && cur.push(next);
            return cur;
          }, []);
          console.log(_this.dataSource);
        }
        if (!_this.showDisable) {
          _this.columns = [].concat(_toConsumableArray(_this.columns), [{
            title: "操作",
            key: "action",
            dataIndex: "action",
            width: 100,
            fixed: "right",
            scopedSlots: {
              customRender: "action"
            }
          }]);
        }
      });
    },
    handleAdd: function handleAdd(val) {
      var _this2 = this;
      if (val) {
        // this.createForm.resetFields();
        this.$router.go(-1);
        return;
      }
      if (this.createForm.getFieldValue("type") == 2) {
        this.createForm.validateFields(function (err, value) {
          var name = value.name,
            type = value.type;
          var begintime = _this2.begintime,
            endtime = _this2.endtime;
          console.log("111", _this2.dataSource);
          var productids = _this2.dataSource.map(function (v) {
            return v.id;
          });
          console.log("222", productids);
          if (!err) {
            var params = {
              name: name,
              begintime: begintime,
              endtime: endtime,
              type: type,
              productids: productids || undefined,
              id: _this2.editId
            };
            HotWordApi.updatehotWord(params).then(function (res) {
              if (res) {
                _this2.$router.push("/hotWord/index");
              }
            });
          } else {
            console.log(err);
          }
        });
      } else {
        this.createForm.validateFields(function (err, value) {
          var name = value.name,
            type = value.type;
          var begintime = _this2.begintime,
            endtime = _this2.endtime;
          if (!err) {
            var params = {
              name: name,
              begintime: begintime,
              endtime: endtime,
              type: type,
              id: _this2.editId
            };
            HotWordApi.updatehotWord(params).then(function (res) {
              if (res) {
                _this2.$router.push("/hotWord/index");
              }
            });
          } else {
            console.log(err);
          }
        });
      }
    },
    // 删除
    deleteRow: function deleteRow(id) {
      this.dataSource = this.dataSource.filter(function (v) {
        return v.id != id;
      });
    },
    previewHandleCancel: function previewHandleCancel() {
      this.previewVisible = false;
    },
    preview: function preview() {
      var previewImage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
      this.previewImage = previewImage;
      this.previewVisible = true;
    },
    //   // 活动时间
    onDateChangeTime: function onDateChangeTime(e, times) {
      console.log(times);
      this.begintime = times[0];
      this.endtime = times[1];
    },
    // 日期校验
    disabledDate: function disabledDate(current) {
      return current && current < this.$moment().subtract(1, "days"); //当天之前的不可选，不包括当天
      //return current && current < moment().endOf(‘day');当天之前的不可选，包括当天
    }
  }
};