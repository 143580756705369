import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "热搜词"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "请填写"
        }]
      }],
      expression: "[\n                        'name',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请填写',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    attrs: {
      disabled: _vm.showDisable
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "时间"
    }
  }, [_c("a-range-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["time", {
        rules: [{
          required: true,
          message: "请填写时间"
        }]
      }],
      expression: "[\n                        'time',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请填写时间',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    attrs: {
      disabled: _vm.showDisable,
      "show-time": "",
      format: "YYYY-MM-DD HH:mm:ss"
    },
    on: {
      change: _vm.onDateChangeTime
    }
  }), _c("span", [_vm._v("（美国时间）")])], 1), _c("a-form-item", {
    attrs: {
      label: "搜索结果"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["type", {
        rules: [{
          required: true,
          message: "请填写"
        }]
      }],
      expression: "[\n                        'type',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请填写',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      placeholder: "请选择",
      size: "large",
      disabled: true
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 搜索词 ")]), _c("a-select-option", {
    attrs: {
      value: 2
    }
  }, [_vm._v(" 自定义商品 ")])], 1)], 1), !_vm.showDisable && _vm.createForm.getFieldValue("type") == 2 ? _c("a-form-item", {
    staticClass: "star",
    attrs: {
      label: "添加商品"
    }
  }, [_c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["hotWord:editWord:add"],
      expression: "['hotWord:editWord:add']"
    }, {
      name: "show",
      rawName: "v-show",
      value: _vm.type = 2,
      expression: "(type = 2)"
    }],
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: _vm.addList
    }
  }, [_vm._v("新增")])], 1) : _vm._e()], 1), _vm.createForm.getFieldValue("type") == 2 ? _c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return it.id;
      },
      loading: _vm.loading
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (_ref) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record) {
          return [key == "parentcategoryname" ? _c("span", [_c("span", [_vm._v(_vm._s("".concat(record.parentcategoryname, " \u2014\u2014 ").concat(record.categoryname)))])]) : key == "imgurls" ? _c("span", [record.imgurls ? _c("img", {
            staticClass: "product_img",
            attrs: {
              src: _vm.splitImg(record)
            },
            on: {
              click: function click($event) {
                _vm.preview(_vm.splitImg(record));
              }
            }
          }) : _c("span", [_vm._v(" --- ")])]) : key == "action" ? _c("span", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["hotWord:editWord:del"],
              expression: "['hotWord:editWord:del']"
            }],
            staticClass: "delete",
            on: {
              click: function click($event) {
                return _vm.deleteRow(record.id);
              }
            }
          }, [_vm._v(" 删除 ")]) : _c("span", [_vm._v(_vm._s(_vm.filterRecordValue(record[key])))])];
        }
      };
    })], null, true)
  }) : _vm._e(), _c("div", {
    staticClass: "footer"
  }, [_c("span", [_vm.showDisable ? _c("a-button", {
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd(1);
      }
    }
  }, [_vm._v("返回")]) : _c("a-button", {
    staticClass: "btn_l",
    attrs: {
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd(1);
      }
    }
  }, [_vm._v("取消")])], 1), !_vm.showDisable ? _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["hotWord:editWord:yes"],
      expression: "['hotWord:editWord:yes']"
    }],
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd();
      }
    }
  }, [_vm._v("确定")]) : _vm._e()], 1), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.previewHandleCancel
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };